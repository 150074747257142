import React, { useEffect } from "react";
import { Container, Row, Col, Card, Tab, Tabs, TabContent, Badge } from "react-bootstrap";
import Toolbar from "@material-ui/core/Toolbar";
import Config from "../../store/Config/index.jsx";
import { Helmet } from "react-helmet";

export default function ReturnPolicy() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const pageData = {
        title: "Return Policy",
        excerpt:
            "This Privacy Policy describes Our policies and procedures on the collection, use and disclosure of Your information when You use the Service and tells You about Your privacy rights and how the law protects You.",
    };

    return (
        <section className="privacy-policy pt-100 pb-70">
            <Helmet>
                <title>
                    {pageData.title} | {Config.challengers__title}
                </title>
                <link rel="canonical" href={window.location.href} />
                <meta property="og:url" content={window.location.href} />
                <meta property="og:type" content="Pc Build website" />

                <meta property="og:title" content={pageData.title} />
                <meta itemprop="name" content={pageData.title} />
                <meta name="twitter:title" content={pageData.title} />

                <meta property="og:description" content={pageData.excerpt} />
                <meta itemprop="description" content={pageData.excerpt} />
                <meta name="twitter:description" content={pageData.excerpt} />
            </Helmet>

            <Container>
                <div className="text-white">
                    <h1>Return Policy</h1>
                    <p>We will try our best to not to create such situations for our clients.</p>
                    <p>
                        <a href="/">ChallengerBuildYourPc</a> reserves all rights to cancel any order for
                        any reason. Orders may be cancelled for the following reasons:
                    </p>
                    <ul className="ps-4">
                        <li>Unavailability of the product</li>
                        <li>Errors in the details provided(us or customer)</li>
                        <li>
                            Problems identified by Credit and Fraud Avoidance department payment gateway
                            fees will deducted and balance will be refunded in case of Fraud Transaction
                        </li>
                    </ul>
                    <p>
                        In the unfortunate case of an order being cancelled by us, we will keep you
                        posted on the same and 100% of your payment will be credited back to your bank
                        account.
                    </p>
                    <p>The refunds will be processed within 15 working days.</p>
                </div>
            </Container>
        </section>
    );
}
