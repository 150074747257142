import React, { Component } from 'react';
import orderPlaced from '../../assets/order.gif';
import { connect } from 'react-redux';
import {clearCart} from "../../store/actions/storeFront";

class PaymentSuccess extends Component {
    
    componentDidMount(){
        window.scrollTo(0,0);
        this.props.clearCart();
    }

    render(){
        return (
            <div style={{ textAlign:'center', marginTop:125, marginBottom:125}}>
                <img src={orderPlaced} style={{height:200, borderRadius:100,}} alt="correct"/>
                <p style={{textAlign:'center', fontSize:30, marginTop:35}}>Payment Success</p>
            </div>
        )
    }
}

const mapDispatchToProps = dispatch => {
    return {
        clearCart: () => dispatch(clearCart())
    };
};

export default connect(null, mapDispatchToProps)(PaymentSuccess);