export const SET_PRODUCTS = 'SET_PRODUCTS';
export const PRODUCT_DETAILS = 'PRODUCT_DETAILS';
export const INIT_PREBUILDS = 'INIT_PREBUILDS';
export const INIT_LAPTOPS = 'INIT_LAPTOPS';
export const INIT_PCPARTS = 'INIT_PCPARTS';
export const SET_FILTERS = 'SET_FILTERS';
export const FILTER = 'FILTER';
export const TOGGLE_LOAD = 'TOGGLE_LOAD';
export const SEARCH_PRODUCT = 'SEARCH_PRODUCT';
export const CART_ADDED = 'CART_ADDED';
export const SUGGESTION_CART = 'SUGGESTION_CART';
export const CART_REMOVED = 'CART_REMOVED';
export const CLEAR_CART = 'CLEAR_CART';
export const QUANTITY = 'QUANTITY';
export const CUSTOM_QUANTITY = 'CUSTOM_QUANTITY';
export const ERROR = 'ERROR';
export const NOT_FOUND = 'NOT_FOUND';
export const EMPTY_SEARCH = 'EMPTY_SEARCH';
export const SELECTED_GAMES = 'SELECTED_GAMES';
export const ADD_TO_WISHLIST = 'ADD_TO_WISHLIST';
export const REMOVE_TO_WISHLIST = 'ADD_TO_WISHLIST';
export const COUPON = 'COUPON';
export const REMOVE_COUPON = 'REMOVE_COUPON';
export const ADD_CUSTOM_PC = 'ADD_CUSTOM_PC';
export const REMOVE_CUSTOM_PC = 'REMOVE_CUSTOM_PC';
export const PAGE = 'PAGE';