import React, { Component } from 'react';
import emptyCart from '../../assets/empty.png';

export default class PaymentFailure extends Component {
    componentDidMount(){
        window.scrollTo(0,0);
    }

    render(){
        return (
            <div style={{ textAlign:'center', marginTop:125, marginBottom:125}}>
                <img src={emptyCart} style={{height:200, borderRadius:100,}} alt="correct"/>
                <p style={{textAlign:'center', fontSize:30, marginTop:35}}>Payment Failed</p>
            </div>
        )
    }
}

